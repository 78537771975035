import React, { useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { storeDetails } from "./store/index";
import { Provider, observer } from "mobx-react";
import { Preloader } from "./components";
import Config from "./config/config";
import ApiLink from "./config/api-link";
import { GetData } from "./services/authenticated-api-client";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") !== null)
      loadData();
    else
      setLoaded(true);
  }, []);

  const loadData = async () => {
    const staticData = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_STATIC}`
    );
    const allData = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_ALL}`
    );
    const activeSuppliers = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_ACTIVE_SUPPLIERS}`
    );
    const pendingSuppliers = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_PENDING_SUPPLIERS}`
    );
    const categories = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_ALL_CATEGORIES}`
    );
    const articles = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_ALL_ARTICLES}`
    );
    const texts = await GetData(
      `${Config.SERVICE_API_URL}${ApiLink.GET_ALL_TEXTS}`
    );

    allData.pages = allData.pages.reverse();

    storeDetails.setStaticData(staticData);
    storeDetails.setAllData(allData);
    storeDetails.setActiveSuppliers(activeSuppliers);
    storeDetails.setPendingSuppliers(pendingSuppliers);
    storeDetails.setCategories(categories);
    storeDetails.setArticles(articles);
    storeDetails.setTexts(texts);

    setLoaded(true);
  };

  return (
    <React.Fragment>
      {loaded ? (
        <Provider storeDetails={storeDetails}>
          <HashRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </HashRouter>
        </Provider>
      ) : (
        <Preloader />
      )}
    </React.Fragment>
  );
};

export default observer(App);
