import axios from "axios"

export function getCurrentUserAuthorization() {
  return `Bearer ${localStorage.getItem('token')}`.trim()
}

export async function GetData(url, options) {
  const authorization = getCurrentUserAuthorization()
  const data = await axios.get(url, {
    headers: {
      Authorization: authorization,
    },
    params: {
      ...(options != null ? options : {}),
    },
	validateStatus: () => true
  })
  
  if(data.status === 401)
  {
	  localStorage.clear();
	  window.location.reload(false);
  }
  
  return data.data
}

export async function PostData(url, body, options) {
  const authorization = getCurrentUserAuthorization()

  return (
    await axios.post(url, body, {
      headers: {
        Authorization: authorization,
      },
      params: {
        ...(options != null ? options : {}),
      },
    })
  ).data
}

export async function PutData(url, body, options) {
  const authorization = getCurrentUserAuthorization()

  return (
    await axios.put(url, body, {
      headers: {
        Authorization: authorization,
      },
      params: {
        ...(options != null ? options : {}),
      },
    })
  ).data
}
