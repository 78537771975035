export default class ApiLink {
  /* Get api link */
  static GET_STATIC = '/admin/statistics'
  static GET_ALL = '/page/all'
  static GET_ACTIVE_SUPPLIERS = '/admin/activeSuppliers'
  static GET_PENDING_SUPPLIERS = '/admin/pendingSuppliers'
  static GET_SUPPLIER_ID = '/supplier/' /* /supplier/:id */
  static GET_ALL_CATEGORIES = '/supplier/category/all'
  static GET_ALL_ARTICLES = '/article/all'
  static GET_ALL_TEXTS = '/text/all'

  /* Post api link */
  static POST_SUPPLIER_EDIT = '/supplier/edit'
  static POST_SUPPLIER_CHANGEPASS = '/supplier/changepass'
  static POST_SUPPLIER_MODIFYPRICE = '/supplier/modifyPrice'
  static POST_ADMIN_APPROVE_SUPPLIER = '/admin/approveSupplier'
  static POST_ADMIN_DECLINE_SUPPLIER = '/admin/declineSupplier'
  static POST_ADMIN_CATEGORY_ADD = '/admin/category/add'
  static POST_ADMIN_CATEGORY_EDIT = '/admin/category/edit'
  static POST_ADMIN_CATEGORY_DELETE = '/admin/category/delete'
  static POST_ARTICLE_EDIT = '/article/edit'
  static POST_ARTICLE_DELETE = '/article/delete'
  static POST_ARTICLE_CREATE = '/article/create'
  static POST_AUTH = '/user/auth'
  static POST_VERIFY = '/user/verify'
  static POST_EDIT_TEXTS = '/text/edit'
}
