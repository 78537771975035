import { autorun, configure, makeAutoObservable } from "mobx";

configure({ enforceActions: "always" });

export class StoreDetails {
  staticData = {};
  allData = {};
  activeSuppliers = {};
  pendingSuppliers = {};
  categories = {};
  articles = {};
  texts= {};

  constructor() {
    this.load();
    autorun(this.save);
    makeAutoObservable(this);
  }

  save = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        StoreDetails.name,
        JSON.stringify({
          staticData: this.staticData,
          allData: this.allData,
          activeSuppliers: this.activeSuppliers,
          pendingSuppliers: this.pendingSuppliers,
          categories: this.categories,
          articles: this.articles,
          texts: this.texts,
        })
      );
    }
  };

  load = () => {
    if (typeof window !== "undefined") {
      Object.assign(
        this,
        JSON.parse(window.localStorage.getItem(StoreDetails.name) || "{}")
      );
    }
  };

  setStaticData = (staticData) => {
    this.staticData = staticData;
    this.save();
  };

  setAllData = (allData) => {
    this.allData = allData;
    this.save();
  };

  setActiveSuppliers = (activeSuppliers) => {
    console.log(activeSuppliers);
    this.activeSuppliers = activeSuppliers;
    this.save();
  };

  setTexts = (texts) => {
    this.texts = texts;
    this.save();
  };

  setPendingSuppliers = (pendingSuppliers) => {
    this.pendingSuppliers = pendingSuppliers;
    this.save();
  };

  setCategories = (categories) => {
    this.categories = categories;
    this.save();
  };

  setArticles = (articles) => {
    this.articles = articles;
    this.save();
  };
}

export default new StoreDetails();
